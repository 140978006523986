/**
 * @copyright 2016-present Kriasoft (https://git.io/Jt7GM)
 */

import type { Route } from "../../core";
import type Home from "./Home";

/**
 * Homepage route.
 */
export default {
  path: "/",
  // query: graphql`
  //   query homeQuery {
  //     me {
  //       id
  //       name
  //       email
  //     }
  //   }
  // `,
  component: () => import(/* webpackChunkName: "home" */ "./Home"),
  response: (data) => ({
    title: "Theory Bay • Social experiments made easy!",
    description: "The web platform for conducting social experiments.",
  }),
} as Route<typeof Home>;
